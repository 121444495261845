import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SelectFriendReservedComponent } from "./select-friend-reserved/select-friend-reserved.component";
import { SelectTicketsReservedComponent } from "./select-tickets-reserved/select-tickets-reserved.component";
import { CheckoutComponent } from "../checkout/checkout.component";
import { SummaryComponent } from "../summary/summary.component";

const routes: Routes = [
    {
      path:      '',
      title:    'Reserved Tickets',
      component: SelectFriendReservedComponent
    },
    {
      path:     'select-tickets',
      title:    'Select Tickets',
      component: SelectTicketsReservedComponent
    },
    {
      path:     'checkout',
      title:    'Checkout',
      component: CheckoutComponent
    },
    {
      path:     'summary/:status',
      title:    'Summary | Reserved Tickets',
      component: SummaryComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReservedTicketsRoutingModule {}
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Reservation } from 'src/app/shared/models/reservations.model';

@Component({
  selector: 'app-reserved-ticket-card',
  templateUrl: './reserved-ticket-card.component.html',
  styleUrl: './reserved-ticket-card.component.css'
})
export class ReservedTicketCardComponent {

  @Input({required: true}) 
  reservation!: Reservation;

  @Input()
  isSelected!: boolean;

  @Output()
  selectReservation: EventEmitter<number> = new EventEmitter<number>();

  public select(): void {
    this.selectReservation.emit(this.reservation.id);
  }
  
}

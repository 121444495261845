import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservedTicketsComponent } from './reserved-tickets.component';
import { ReservedTicketsRoutingModule } from './reserved-tickets-routing.module';
import { SelectFriendReservedComponent } from './select-friend-reserved/select-friend-reserved.component';
import { ReservedFriendCardComponent } from './reserved-friend-card/reserved-friend-card.component';
import { LoaderModule } from 'src/app/shared/components/globals/loader/loader.module';
import { FooterModule } from 'src/app/shared/components/globals/footer/footer.module';
import { SelectTicketsReservedComponent } from './select-tickets-reserved/select-tickets-reserved.component';
import { BackButtonModule } from 'src/app/shared/components/globals/back-button/back-button.module';
import { ReservedTicketCardComponent } from './select-tickets-reserved/reserved-ticket-card/reserved-ticket-card.component';
import { CheckoutService } from 'src/app/core/services/checkout.service';
import { ReservationTransactionService } from 'src/app/core/services/override/checkout/reservation-transaction.service';
import { CheckoutModule } from '../checkout/checkout.module';
import { SummaryModule } from '../summary/summary.module';



@NgModule({
  declarations: [
    ReservedTicketsComponent,
    SelectFriendReservedComponent,
    ReservedFriendCardComponent,
    SelectTicketsReservedComponent,
    ReservedTicketCardComponent,
  ],
  imports: [
    CommonModule,
    BackButtonModule,
    LoaderModule,
    CheckoutModule,
    SummaryModule,
    FooterModule,
    ReservedTicketsRoutingModule,
  ],
  providers: [
    {
      provide: CheckoutService,
      useClass: ReservationTransactionService
    }
  ]
})
export class ReservedTicketsModule { }

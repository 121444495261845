<div class="flex flex-col bg-white rounded-2xl" [ngClass]="{'ring ring-secondary/80': isSelected}">

    <!-- HEADER SUPERIOR -->
    <div class="flex justify-between mx-5 h-16 border-b items-center">
        
        <p class="font-semibold text-darkgray">
            Order Id: 
            <span class="font-main text-secondary">
                {{reservation.id}}
            </span>
        </p>

        <button [ngClass]="{'button-secondary': isSelected}" class="button w-3/12 sm:w-5/12 lg:w-3/12 py-1" (click)="select()">
            
            <!-- SELECT -->
            <ng-container *ngIf="!isSelected else selected">
                <span>
                    Select
                </span>
            </ng-container>
            
            <!-- SELECTED -->
            <ng-template #selected>
                <!-- TICK -->
                &#10004;&#xFE0E; 
                
                <span class="hidden sm:inline-block">
                    Selected
                </span>
            </ng-template>

        </button>
    </div>
    
    <!-- CONTENIDO INFERIOR -->
    <div class="flex flex-col my-2">

        <div class="flex flex-col px-6" *ngFor="let item of reservation.pending_items">
            <!-- NOMBRE DEL EVENTO Y CANTIDAD DE TICKETS -->
            <div class="flex justify-between">
                
                <p class="w-5/6 font-medium text-darkgray">
                    {{item.name}}
                </p>
                
                <!-- CANTIDAD DE TICKETS -->
                <p class="w-1/6 text-end">
                    {{ reservation.pending_items.length }}
                    {{ reservation.pending_items.length === 1 ? 'Ticket' : 'Tickets' }}
                </p>
                
            </div>

            <!-- DETALLES, PRECIO Y BUYERTYPE -->
            <div class="flex justify-between gap-x-2" *ngFor="let seat of item.seats">
                <span class="block text-darkgray/70 text-xs w-7/12" >
                    Section: {{seat.tdc_section}} - Row: {{seat.tdc_seat_row}} - Seat: {{seat.tdc_seat_number}}
                </span>
                <span  class="block text-darkgray/70 text-xs text-right w-5/12">
                    {{seat.buyer_type}} - {{seat.price | currency:'GBP'}}
                </span>
            </div>

        </div>

    </div>
</div>
import { Component, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AssociationService } from 'src/app/core/services/association.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ReservationsService } from 'src/app/core/services/reservations.service';
import { AssociationCollection, AssociationComplete } from 'src/app/shared/models/association.model';
import { TdcInfo, User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-select-friend-reserved',
  templateUrl: './select-friend-reserved.component.html',
  styleUrl: './select-friend-reserved.component.css'
})
export class SelectFriendReservedComponent {

  /**
   * SERVICIOS
   * 
   * 1. authService:        Servicio de Autenticación
   * 2. associationService: Servicio de Asociaciones
   */
  private authService:        AuthService         = inject(AuthService);
  private associationService: AssociationService  = inject(AssociationService);
  private reservationService: ReservationsService = inject(ReservationsService);
  private router:             Router              = inject(Router);

  /**
   * DATA
   * 
   * 1. user:            Datos del Usuario
   * 2. associations:    Asociaciones del Usuario como Array de objetos (signal)
   */
  protected user:             User                          = this.authService.userData() as User;
  protected associations:     Signal<AssociationComplete[]> = this.associationService.associationsArray;
  protected selectedUsers:    WritableSignal<number[]>      = signal([this.user.tdc_info.id]);

  /**
   * Template variables
   * Definen los textos que se muestran en la vista
   * 
   * 1. header:    Título de la vista
   * 2. subheader: Subtítulo de la vista
   * 3. separator: Texto que se muestra en el separador de las cards
   */
  public templateVars:        {header:string, subheader:string, separator:string} = {
    header:    'Reserved Tickets',
    subheader: 'Select friends or family you would like to claim and purchase tickets for:',
    separator: 'Renew tickets on behalf of:'
  };

  /**
   * Filtra el array de seleccionados para que no se incluya el usuario actual.
   * En base a la longitud del array, devuelve un string con el número de amigos seleccionados.
   * Si el array contiene solo un amigo, se mostrará 'friend' en lugar de 'friends'.
   */
  public friendsSelected:     Signal<number[]> = computed(()=>{
    return this.selectedUsers().filter(id => id !== this.user.tdc_info.id);
  });
  
  /**
   * Devuelve el nombre completo de un usuario
   * @param {TdcInfo} data 
   * @returns string
   */
  public formatName(data: TdcInfo): string {
    return `${data.first_name} ${data.last_name}`;
  }

  /**
   * Método llamado por las cards de usuario al ser seleccionadas.
   * Comprueba si el usuario ya ha sido seleccionado y lo añade o elimina del array de usuarios seleccionados.
   * @param userId 
   */
  protected updateSelectedUsers(userId: number): void {
    const index: number = this.selectedUsers().indexOf(userId);
    
    if (index === -1) {
      this.selectedUsers.update(value => [...value, userId]);
    } else {
      this.selectedUsers.update(value => value.filter(id => id !== userId));
    }

  }

  /**
   * Método llamado al pulsar el botón 'Continue'.
   * Crea un objeto con las asociaciones seleccionadas y las guarda en el servicio de asociaciones.
   * Redirige a la vista de selección de tickets.
   */
  protected nextStep(): void {
    const data: AssociationCollection    = {};
    const userAsAssociation: AssociationComplete = {
      id:           this.user.tdc_info.id,
      name:         this.user.tdc_info.account_name,
      associate_id: this.user.tdc_info.id,
      tdc_info:     this.user.tdc_info,
    };
    
    // Añade el usuario actual y los amigos seleccionados al objeto de asociaciones
    data[this.user.tdc_info.id] = userAsAssociation;
    this.friendsSelected().forEach(id => data[id] = this.associationService.associationsComplete()![id])
    
    // Guarda las asociaciones seleccionadas en el servicio
    this.associationService.setSelected(data);
    this.reservationService.getReservations(this.selectedUsers());
    this.router.navigate(['renew-tickets/select-tickets'],{queryParams:{associations: this.selectedUsers(), type: 'reservations'}})
  }

}

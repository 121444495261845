import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutService } from '../../checkout.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationTransactionService extends CheckoutService<any> {

  constructor(
    router: Router,
    http:   HttpClient
  ){
    super(router,http);
  }

  public override returnTo:    string = 'renew-tickets';
  protected override endpoint: string = '/reservation_transaction/'
}

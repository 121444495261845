import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TdcInfo } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-reserved-friend-card',
  templateUrl: './reserved-friend-card.component.html',
  styleUrl: './reserved-friend-card.component.css'
})
export class ReservedFriendCardComponent {

  @Input({required: true})
  public name!:        string;

  @Input({required: false})
  public email!:       string;
  
  @Input({required: false})
  public supporterId!: number;
  
  @Input({required: false})
  public extraData!:   TdcInfo;

  @Input({required: true})
  public isSelected!:  boolean;
  
  @Input({required: false})
  public customText!:  string;

  @Input({required: false})
  public showButton:   boolean = true;

  @Input({required: false})
  public canExpand:    boolean = false;

  @Input({required: false})
  public force:        boolean = false;

  @Output()
  public selectUser:   EventEmitter<number> = new EventEmitter<number>();

  public isExpanded:   boolean = false;

  public toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  public onSelect(): void {
    if (this.force) {
      return;
    }
    this.selectUser.emit(this.supporterId);
  }

  protected getTotalPoints(loyaltyProgram: TdcInfo['loyalty_programs']): number {
    return loyaltyProgram.reduce((acc, program) => acc + (program.current_point_balance ?? 0), 0);
  }

}

<section class="h-full overflow-hidden">
    
    <!-- TITLE & BACK BUTTON -->
    <div class="h-1/5">
        
        <div class="h-1/5 lg:h-auto">
            <app-back-button/>
        </div>
        
        <div class="w-full flex justify-center items-center h-4/5 lg:h-auto">
            <h3 class="text-xl font-semibold text-darkgray lg:text-3xl xl:text-4xl">
                {{ templateVars.title }}
            </h3>
        </div>

    </div>

    <!-- MAIN CONTENT -->
    <div class="h-4/5 bg-white shadow p-5 overflow-y-auto">
        
        <!-- INNER CONTAINER -->
        <div class="container mx-auto lg:pb-24">

            <!-- TEXT DETAIL -->
            <p class="text-center text-darkgray/80 my-5">
                <span>
                    {{ templateVars.subtitle }}
                </span>
            </p>
            
            <!-- CONTENT -->
            <div class="w-full lg:w-9/12 xl:max-w-4xl mx-auto flex flex-col gap-y-3">

                <!-- CARDS -->
                <ng-container *ngIf="(currentUser() || friends()) && reservations() else loader">
                    
                    <!-- CURRENT USER -->
                    <app-reserved-friend-card
                        [name]="currentUser()?.name!"
                        [isSelected]="false"
                        [showButton]="false"
                        [canExpand]="hasAssociations(currentUser()?.id!)"
                        [customText]="
                            hasAssociations(currentUser()?.id!) ? 
                            reservations()![currentUser()?.id!].length + ' reservation/s available' : '0 reservations available'"
                    >
                        <div class="container mx-auto p-5 flex flex-col gap-y-4 ">
                            <app-reserved-ticket-card 
                                *ngFor="let reservation of reservations()![currentUser()?.id!]" 
                                [reservation]="reservation"
                                [isSelected]="
                                    selectedReservations[currentUser()?.id!] && selectedReservations[currentUser()!.id].includes(reservation.id) ?
                                    true : false"
                                (selectReservation)="updateReservations($event, currentUser()?.id!)"
                            />
                        </div>
                    
                    </app-reserved-friend-card>
    
                    <!-- FRIENDS -->
                    <app-reserved-friend-card
                        *ngFor="let friend of friends()"
                        [name]="friend.name"
                        [isSelected]="false"
                        [showButton]="false"
                        [canExpand]="hasAssociations(friend.associate_id!)"
                        [customText]="
                            hasAssociations(friend.associate_id) ? 
                            reservations()![friend.associate_id].length + ' reservation/s available': '0 reservations available'"                       
                    >

                    <div class="container mx-auto p-5 flex flex-col gap-y-4 ">
                        <app-reserved-ticket-card 
                            *ngFor="let reservation of reservations()![friend.associate_id!]" 
                            [reservation]="reservation"
                            [isSelected]="
                                selectedReservations[friend.associate_id] && selectedReservations[friend.associate_id].includes(reservation.id) ?
                                true : false
                            "
                            (selectReservation)="updateReservations($event, friend.associate_id)"
                        />
                    </div>
                       
                    </app-reserved-friend-card>

                </ng-container>

                <!-- LOADER -->
                <ng-template #loader>
                    <div class="mx-auto">
                        <app-loader/>
                    </div>
                </ng-template>

            </div>

        </div>

    </div>

</section>

<app-footer
    [buttonText]="'Go to Checkout'"
    [showFooter]="totalReservations > 0"
    [textDetail]="totalReservations + '/' + maxReservations + ' tickets selected'"
    [disableButton]="totalReservations === 0"
    (footerEvent)="startCheckout()"
/>
import { HttpClient } from '@angular/common/http';
import { EffectRef, Injectable, Signal, WritableSignal, computed, effect, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssociationService } from './association.service';
import { ReservationCollection } from 'src/app/shared/models/reservations.model';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  constructor(
    private http:         HttpClient,
    private route:        ActivatedRoute,
    private associations: AssociationService
  ) {}

  private endpoint:      string = '/customer/reservations/'

  private reservations$: WritableSignal<ReservationCollection|undefined> = signal(undefined);
  public  reservations:  Signal<ReservationCollection | undefined>       = computed(()=> this.reservations$())

  public getReservations(associate_ids: number[] | string[]): void {
   
    if(this.reservations$()){
      this.reservations$.set(undefined);
    }

    this.http.get<ReservationCollection>(this.endpoint, {params: {friend_family_account: associate_ids}}).subscribe({
      next:  (reservations) => this.reservations$.set(reservations),
      error: (error) => console.error(error)
    })

  }

  private _recoverReservations: EffectRef = effect((): void =>{
    if(
      this.route.snapshot.queryParams['type'] === 'reservations' && 
      this.associations.selected() && 
      this.reservations$() === undefined
    ){
      this.getReservations(Object.keys(this.associations.selected()!));
      return;
    }
  },{allowSignalWrites:true})

}
